<template>
  <div class="cardForm">
    <template v-if="plan">
      <div class="order-summary">
        <div class="title">Order Summary</div>
        <table v-if="plan">
          <thead>
            <tr>
              <th></th>
              <th style="min-width: 120px;">Total Due Today</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div v-if="isBusiness" class="text-left">
                  <strong>{{ plan.label }} Account:</strong> <span class="price-span"
                    :class="{ 'line-through': couponInfo }">${{ formatPrice(plan.amount_org) }}</span><span
                    v-if="couponInfo" class="price-span">${{ formatPrice(this.planPrice) }}</span> Per <span
                    class="text-capitalize">{{ plan.interval }}</span> -
                  <span class="promotion-count">{{ plan.features.Installs }}</span> Installs, <span class="promotion-count">{{ plan.features.Users ?
                    plan.features.Users : 'Unlimited' }}</span> Users,<span v-if="plan.features.Zapier"><span
                      class="promotion-count"> Zapier</span> Integration,</span><span
                    v-if="plan.features.Department"><span class="promotion-count"> Department</span> Feature,</span>
                    <span class="promotion-count"> {{ Number(plan.features.Messages) ?
                    plan.features.Messages : 'Unlimited' }}</span> Messages,<span class="promotion-count"> {{ Number(plan.features.Calls) ?
                    plan.features.Calls : 'Unlimited' }}</span> Calls
                </div>
                <div v-else class="text-left">
                  <strong>Agency {{ plan.label }} Account:</strong> <span v-if="showTrial"><span class="trial-span">{{
                    plan.trial_period_days }} Days Free Trial</span> Then</span> <span class="price-span"
                    :class="{ 'line-through': couponInfo }">${{ formatPrice(plan.amount_org) }}</span><span
                    v-if="couponInfo" class="price-span">${{ formatPrice(this.planPrice) }}</span> Per <span
                    class="text-capitalize">{{ plan.interval }}</span> -
                  <span class="promotion-count">{{ plan.features.Installs }}</span> Accounts, <span class="promotion-count">{{ plan.features.Users ?
                    plan.features.Users : 'Unlimited' }}</span> Users,<span v-if="plan.features.Zapier"><span
                      class="promotion-count"> Zapier</span> Integration,</span><span
                    v-if="plan.features.Department"><span class="promotion-count"> Department</span> Feature,</span> 100%
                  White Label, Unlimited Text Messages, Unlimited Calls
                </div>
              </td>
              <td style="vertical-align: top;text-align: right;padding-right: 10px; font-size: 16px;">$<span
                  v-if="showTrial">0.00</span><span v-else>{{ formatPrice(this.planPrice) }}</span></td>
            </tr>
            <tr v-if="couponInfo">
              <td class="text-center">( Coupon <b>{{ couponInfo.name }}</b> is applied )</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="showCoupon" class="coupon-wrapper mt-4 mb-3">
        <div class="row">
          <div class="col-md-8">
            <input type="text" class="input" name="couponCode" v-model="couponCode" placeholder="Enter Discount Code" />
          </div>
          <div class="col-md-4 d-flex justify-content-end">
            <button @click="applyCoupon" type="button" class="btn btn-default btn-success coupon-button"
              :disabled="couponButtonDisabled">
              <atom-spinner v-if="isLoadingCard || loading" slot="loading" :animation-duration="1500" :size="20"
                color="#FFF" />
              <span v-else>Apply</span>
            </button>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="field">
        <label for="card-number-element">Credit Card Number</label>
        <div id="card-number-element" class="input empty"></div>
      </div>
    </div>

    <div class="row">
      <div class="field half-width">
        <label for="card-expiry-element">Expiration</label>
        <div id="card-expiry-element" class="input empty"></div>
      </div>

      <div class="field half-width">
        <label for="card-cvc-element">CVC</label>
        <div id="card-cvc-element" class="input empty"></div>
      </div>
    </div>

    <button v-if="buttonText" id="card-button" class="btn btn-default btn-primary" @click="checkCard"
      :disabled="buttonDisabled || isLoadingCard || loading" type="button">
      <atom-spinner v-if="isLoadingCard || loading" slot="loading" :animation-duration="1500" :size="20" color="#FFF" />
      <span v-else>{{ buttonText }}</span>
    </button>

    <div class="error visible" role="alert" v-if="error">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
        <path class="base" fill="#000"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z">
        </path>
        <path class="glyph" fill="#FFF"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z">
        </path>
      </svg>
      <span class="message">{{ error }}</span>
    </div>

    <div class="credit-card-only mt-3">
      <img src="@/assets/images/credit-only.webp" alt="Credit Card">
      <span class="d-block mt-3">We use a secure payment gateway - Stripe - that processes payments on our behalf. We
        don't store credit or debit card details on our website. All payment data is securely stored by a third-party
        PCI-compliant payment gateway.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonDisabled: {},
    buttonText: {},
    addPaymentMethod: {
      default: true,
    },
    plan: {},
    loading: {},
    showCoupon: {
      type: Boolean,
      default: true,
    },
  },

  components: {
  },

  data() {
    return {
      stripeAPIToken: '',
      clientSecret: '',
      error: null,
      cardElement: null,
      isLoadingCard: false,
      paymentMethodId: null,
      couponCode: undefined,
      couponInfo: undefined,
    }
  },

  mounted() {
    this.stripeAPIToken = this.$store.getters['auth/stripeConfig'];
    this.includeStripe()

    this.$store.dispatch('subscription/getSecret')
      .then(res => {
        this.clientSecret = res.data
      })
  },

  methods: {
    applyCoupon() {
      this.couponInfo = undefined
      this.isLoadingCard = true
      const param = {
        type: 'order',
        code: this.couponCode
      }
      this.$store.dispatch('subscription/getCoupon', param)
        .then((res) => {
          if (res.meta && res.meta.Apply) {
            this.checkCouponApply(res)
          } else {
            this.couponInfo = res
          }
          this.isLoadingCard = false
        })
        .catch(errors => {
          this.isLoadingCard = false
        })
    },

    checkCouponApply(res) {
      let valid = false;
      let couponApply = res.meta ? res.meta.Apply : ''
      switch (couponApply) {
        case 'yearly':
          valid = this.plan && this.plan.interval == 'year' && this.plan.amount_org > res.amount_off / 100 * 2
          break;
        default:
          break;
      }
      if (valid)
        this.couponInfo = res
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    includeStripe() {
      // Stripe library already included
      if (document.getElementById('stripe-js')) {
        this.configureStripe()
        return
      }

      let documentTag = document
      let object = documentTag.createElement('script')
      let scriptTag = documentTag.getElementsByTagName('script')[0]

      object.id = 'stripe-js'
      object.src = '//js.stripe.com/v3/'
      object.addEventListener('load', (e) => { this.configureStripe() }, false)
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },

    configureStripe() {
      let elementStyles = {
        base: {
          color: '#32325D',
          fontWeight: 500,
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',

          '::placeholder': {
            color: '#FFCCA5',
          },
        },
      }

      this.stripe = Stripe(this.stripeAPIToken)
      const elements = this.stripe.elements()

      if (document.getElementById('card-number-element')) {
        this.cardElement = elements.create('cardNumber', {
          style: elementStyles,
        })
        this.cardElement.mount('#card-number-element')
      }

      if (document.getElementById('card-expiry-element')) {
        let cardExpiryElement = elements.create('cardExpiry', {
          style: elementStyles,
        })
        cardExpiryElement.mount('#card-expiry-element')
      }

      if (document.getElementById('card-cvc-element')) {
        let cardCvcElement = elements.create('cardCvc', {
          style: elementStyles,
        })
        cardCvcElement.mount('#card-cvc-element')
      }
    },

    async checkCard() {
      // Card already confirmed
      if (this.paymentMethodId) {
        if (this.addPaymentMethod) {
          this.newPaymentMethod(this.paymentMethodId)
        } else {
          this.$emit('done', {
            id: this.paymentMethodId,
            coupon: this.couponInfo
          })
        }
        return;
      }

      this.error = null

      this.isLoadingCard = true
      const { setupIntent, error } = await this.stripe.confirmCardSetup(this.clientSecret, {
        payment_method: {
          card: this.cardElement,
        },
      })

      if (error) {
        this.error = error.message
        this.isLoadingCard = false
      } else {
        this.paymentMethodId = setupIntent.payment_method

        if (this.addPaymentMethod) {
          this.newPaymentMethod(setupIntent.payment_method)
        } else {
          this.isLoadingCard = false
          this.$emit('done', {
            id: setupIntent.payment_method,
            coupon: this.couponInfo
          })
        }
      }
    },

    newPaymentMethod(paymentMethod) {
      this.isLoadingCard = true
      this.$store.dispatch('subscription/addPaymentMethod', paymentMethod)
        .then((res) => {
          this.$emit('done', {
            id: paymentMethod,
            coupon: this.couponInfo
          })
          this.isLoadingCard = false
        })
        .catch(errors => {
          this.isLoadingCard = false
          this.$emit('failed')
        })
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    isBusiness() {
      return this.user && this.user.role_group === 'Business'
    },

    couponButtonDisabled() {
      return this.loading || !this.couponCode || this.isLoadingCard
    },
    businessUrl() {
      return this.callEnabled ? 'call' : 'message';
    },

    showTrial() {
      return false
    },

    planPrice() {
      let res = this.plan.amount_org;
      if (this.couponInfo) {
        if (this.couponInfo.amount_off)
          res = this.plan.amount_org - this.couponInfo.amount_off / 100
        else if (this.couponInfo.percent_off)
          res = this.plan.amount_org * (100 - this.couponInfo.percent_off) / 100
      }
      return res
    },
  }
}
</script>

<style scoped lang="scss">
.line-through {
  text-decoration: line-through;
}

.atom-spinner {
  margin: 0 auto;
}

.coupon-button {
  margin: 0;
}

.cardForm label {
  color: #414141;
  margin-bottom: 5px;
}

.cardForm .row {
  margin-right: -15px;
  margin-left: -15px;
}

.cardForm .coupon-wrapper {
  margin: 15px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}

.cardForm .credit-card-only {
  padding: 20px 0px;
  text-align: center;
}

.cardForm .order-summary {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  padding-bottom: 15px
}

.cardForm .order-summary .title {
  font-size: 20px;
  font-weight: bold;
}

.cardForm .order-summary table {
  width: 100%;
}

.cardForm .order-summary table th {
  font-weight: bold;
  font-size: 14px;
  color: #414141;
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
}

.cardForm .order-summary table td {
  padding: 10px 0px;
}

.cardForm .order-summary .trial-span {
  font-weight: bold;
  color: #13c70d;
  font-style: italic;
  font-size: 19px;
  padding: 0 4px;
  text-decoration: underline;
}

.cardForm .order-summary .price-span {
  font-weight: bold;
  font-size: 21px;
  display: inline-block;
  padding: 0 5px;
}

span.original-count {
  text-decoration: line-through;
}

span.promotion-count {
  font-weight: bold;
  font-size: 17px;
}
</style>
